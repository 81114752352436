<template>
  <div class="view-promotions">
    <DataTable action="promotions/get" :headers="headers" ref="table">
      <template #cell(title)="{item}">
        <router-link :to="{ name: 'EditPromotion', params: { id: item.id } }">{{
          item.book_title
        }}</router-link>
        <p class="text-muted m-0">{{ item.isbn }}</p>
      </template>

      <template #cell(price)="{item}">
        {{ getBaseCurrencyPrice(item) }}
      </template>

      <template #cell(period)="{item}">
        {{ formatDate(item.start_date) }}
        {{ item.end_date ? " → " + formatDate(item.end_date) : "" }}
      </template>

      <template #cell(status)="{item}">
        <!-- Use statusVariant from @/mixins/colors.js -->
        <h5 class="m-0">
          <b-badge :variant="statusVariant(item.status)" class="mr-1">{{
            $t(item.status)
          }}</b-badge>
        </h5>
      </template>

      <!-- Actions -->
      <template #actions="{item}">
        <b-dropdown class="card-drop" variant="white" right toggle-class="p-0">
          <template v-slot:button-content>
            <i class="mdi mdi-dots-horizontal font-size-18"></i>
          </template>
          <b-dropdown-item
            :to="{ name: 'EditPromotion', params: { id: item.id } }"
            >{{ $t("edit") }}</b-dropdown-item
          >

          <b-dropdown-item @click="remove(item)">{{
            $t("delete")
          }}</b-dropdown-item>
        </b-dropdown>
      </template>
    </DataTable>
  </div>
</template>

<script>
import DataTable from "@/components/data-table";
import { find } from "lodash-es";
import { mapState } from "vuex";

export default {
  name: "view-promotions",
  components: {
    DataTable
  },
  data() {
    return {
      promotions: [],
      page: 1,
      limit: 10,
      headers: [
        {
          key: "title",
          label: "book"
        },
        "price",
        "period",
        "status",
        "actions"
      ],
      currencyByCode: {}
    };
  },
  mounted() {
    this.$store.dispatch("ancillaries/getCurrencies");
  },
  computed: {
    ...mapState("ancillaries", ["currencies"])
  },
  methods: {
    getBaseCurrencyPrice(promotion) {
      const baseCurrency =
        this.currencyByCode[promotion.base_currency] ||
        find(this.currencies, o => o.code === promotion.base_currency);
      if (!baseCurrency) return this.$t("n-a");

      // For faster future runs
      this.currencyByCode[baseCurrency.code] = baseCurrency;

      const basePrice = find(
        promotion.prices,
        o => o.currency_id === baseCurrency.id
      );
      if (!basePrice) return this.$t("n-a");

      return basePrice.price + " " + baseCurrency.code;
    },
    splitKeywords(keywords) {
      return keywords.split(",");
    },
    async remove({ book_title, id }) {
      if (confirm(this.$t("remove-promotion", { title: book_title }))) {
        this.ebpLoader(true);

        try {
          await this.$store.dispatch("promotions/delete", id);
          this.$refs.table.change("id", id);
        } catch (err) {
          console.error(err);
        }

        this.ebpLoader(false);
      }
    }
  }
};
</script>

<style></style>
